<template>
  <AppSidebar />
  <div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg>
        <AppBreadcrumb />
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
  </div>
  <!-- <AppAside /> -->
</template>
<script>
import { CContainer } from '@coreui/vue-pro'
import AppAside from '@/components/AppAside.vue'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppTimerPage from '@/components/AppTimerPage.vue'
export default {
  name: 'DefaultLayout',
  data() {
    return {
      loading: 'mount the appp',
    };
  },
  components: {
    AppAside,
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    AppTimerPage,
  },
  methods: {
    updateTimer(){

    },
    logoutUser() {
      closeSession(this.$router)
    },
  },
  mounted() {
    // Hide the loader once everything is loaded
    this.loading = 'mount the appp complete';
  },
}
</script>
